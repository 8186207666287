<template>
  <div>
    <a class="b" @click="page('login')">
      <svg
        class="icon-mb"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="stroke_green"
          d="M13.0019 7.4795V5.77979C13.0019 3.79033 11.3885 2.17692 9.39904 2.17692C7.40959 2.16821 5.78984 3.77371 5.78113 5.76396V5.77979V7.4795"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          class="stroke_green"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.4159 16.8226H6.36675C4.709 16.8226 3.36475 15.4792 3.36475 13.8206V10.4252C3.36475 8.76661 4.709 7.42316 6.36675 7.42316H12.4159C14.0736 7.42316 15.4179 8.76661 15.4179 10.4252V13.8206C15.4179 15.4792 14.0736 16.8226 12.4159 16.8226Z"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          class="stroke_green"
          d="M9.39144 11.2438V13.0021"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span class="color_green">Личный кабинет</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'LinkPersonalCabinet',
  methods: {
    page(page) {
      location.href = page
    }
  }
}
</script>

<style scoped>
.icon-mb {
  margin-bottom: 3px;
  margin-right: 3px;
}
.color_green {
  color: #04A27C !important;
}
.stroke_green {
  stroke: #04A27C;
}
</style>
